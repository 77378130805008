<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <div class="title">
        {{ $t("nav.interactions") }}
      </div>
    </div>
    <tab-switcher
      ref="tabSwitcher"
      :on-switch="onModeSwitch"
    >
      <span
        key="mentions"
        :label="$t('nav.mentions')"
      />
      <span
        key="likes+repeats"
        :label="$t('interactions.favs_repeats')"
      />
      <span
        key="follows"
        :label="$t('interactions.follows')"
      />
      <span
        key="reactions"
        :label="$t('interactions.emoji_reactions')"
      />
      <span
        v-if="canSeeReports"
        key="reports"
        :label="$t('interactions.reports')"
      />
      <span
        v-if="!allowFollowingMove"
        key="moves"
        :label="$t('interactions.moves')"
      />
    </tab-switcher>
    <Notifications
      ref="notifications"
      :no-heading="true"
      :minimal-mode="true"
      :filter-mode="filterMode"
    />
  </div>
</template>

<script src="./interactions.js"></script>
