import Modal from 'src/components/modal/modal.vue'
import crossoverVideo from 'src/assets/uma_musume_crossover.mp4'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons'
library.add(
  faTimes
)

export const CURRENT_CROSSOVER_COUNTER = 1

const CrossoverNotification = {
  data () {
    return {
      showingImage: false,
      showingMore: false,
      crossover: crossoverVideo
    }
  },
  components: {
    Modal
  },
  computed: {
    shouldShow () {
      return !this.$store.state.instance.disableCrossoverNotifs &&
        this.$store.state.users.currentUser &&
        this.$store.state.serverSideStorage.flagStorage.crossoverCounter < CURRENT_CROSSOVER_COUNTER &&
        !this.$store.state.serverSideStorage.prefsStorage.simple.dontShowCrossoverNotifs
    }
  },
  methods: {
    toggleShow () {
      this.showingMore = !this.showingMore
    },
    neverShowAgain () {
      this.toggleShow()
      this.$store.commit('setFlag', { flag: 'crossoverCounter', value: CURRENT_CROSSOVER_COUNTER })
      this.$store.commit('setPreference', { path: 'simple.dontShowCrossoverNotifs', value: true })
      this.$store.dispatch('pushServerSideStorage')
    },
    dismiss () {
      this.$store.commit('setFlag', { flag: 'crossoverCounter', value: CURRENT_CROSSOVER_COUNTER })
      this.$store.dispatch('pushServerSideStorage')
    }
  }
}

export default CrossoverNotification
