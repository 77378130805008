import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHeadphones,
  faTimes,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeOff,
  faStepForward,
  faStepBackward
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faHeadphones,
  faTimes,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeOff,
  faStepForward,
  faStepBackward
)

const musicPlayer = {
  props: ['floating'],
  data () {
    return {
      currentMessage: '',
      collapsed: true,
      volume: 0,
      progress: 0,
      muted: false
    }
  },
  computed: {
    getCurrentSong () {
      return this.$store.getters.hasSong
    },
    isPlaying () {
      return this.$store.getters.songState
    },
    defaultVolume () {
      return this.$store.getters.mergedConfig.defaultProfileMusicVolume
    }
  },
  methods: {
    nextSong () {
      this.$store.dispatch('playlistNext')
    },
    prevSong () {
      this.$store.dispatch('playlistPrev')
    },
    seekPosition () {
      this.$store.dispatch('setSongPosition', this.progress)
    },
    togglePanel () {
      this.collapsed = !this.collapsed
    },
    togglePlay () {
      this.$store.dispatch('setSongState', !this.isPlaying)
    },
    toggleMute () {
      if (this.muted) {
        this.$store.dispatch('setSongVolume', this.defaultVolume)
      } else {
        this.$store.dispatch('setSongVolume', 0)
      }
      this.muted = !this.muted
    },
    setVolume () {
      this.$store.dispatch('setSongVolume', this.volume)
    },
    clearQueue () {
      this.$store.dispatch('setBackgroundMusic', null)
      this.togglePanel()
    }
  },
  created () {
    this.progress = 0
    this.volume = this.defaultVolume
    setInterval(() => {
      if (this.$store.getters.hasSong) {
        this.progress = this.$store.getters.songProgress
        if (this.progress === 100) {
          this.$store.dispatch('playlistNext')
        }
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval()
  }
}

export default musicPlayer
