<template>
  <div
    v-if="!collapsed || !floating"
    class="music-panel"
  >
    <div class="panel panel-default">
      <div
        class="panel-heading timeline-heading"
        :class="{ 'shout-heading': floating }"
        @click.stop.prevent="togglePanel"
      >
        <div class="title">
          {{ $t('music_player.title') }}
          <FAIcon
            v-if="floating"
            icon="times"
            class="close-icon"
          />
        </div>
      </div>
      <div class="shout-window">
        <div class="music-player-controls">
          <div
            class="music-player-button"
            @click.stop.prevent="togglePlay"
          >
            <div
              @click.stop.prevent="prevSong"
            >
              <FAIcon
                icon="step-backward"
                class="music-player-button"
              />
            </div>
            <div>
            <FAIcon
            v-if="isPlaying"
              icon="pause"
              class="music-player-button"
            />
            <FAIcon
            v-else
              icon="play"
            />
            </div>
            <div
              @click.stop.prevent="nextSong"
            >
              <FAIcon
                icon="step-forward"
                class="music-player-button"
              />
            </div>
            <div
              @click.stop.prevent="toggleMute"
            >
              <FAIcon
                v-if="!muted"
                icon="volume-up"
              />
              <FAIcon
                v-else
                icon="volume-off"
              />
            </div>
          </div>
          <input
            type="range"
            v-model="volume"
            min="0"
            max="100"
            @change.stop.prevent="setVolume"
          />
          <FAIcon
              icon="times"
              class="close-icon"
              @click.stop.prevent="clearQueue"
            />
        </div>
        <div class="music-player-timeline">
          <input
            class="seekbar"
            type="range"
            min="0"
            max="100"
            v-model="progress"
            @change.stop.prevent="seekPosition"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="music-panel"
  >
    <div class="panel panel-default">
      <div
        class="panel-heading -stub timeline-heading shout-heading"
        @click.stop.prevent="togglePanel"
      >
        <div class="title">
          <FAIcon
            class="icon"
            icon="headphones"
          />
          {{ $t('music_player.title') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./music_player.js"></script>

<style lang="scss">
@import '../../_variables.scss';

.floating-music-player {
  position: fixed;
  bottom: 5em;
  z-index: var(--ZI_popovers);
  max-width: 25em;

  &.-left {
    left: 0.5em;
  }

  &:not(.-left) {
    right: 0.5em;
  }
}

.music-panel {
  .shout-heading {
    cursor: pointer;

    .icon {
      color: $fallback--text;
      color: var(--panelText, $fallback--text);
      margin-right: 0.5em;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title svg {
      float: right;
    }
  }
  .shout-window {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 20em;
    padding: 2em;
    column-gap: 1em;

    .music-player-controls {
      display: inline-flex;
      column-gap: 1em;

      .music-player-button {
        display: inline-flex;
        column-gap: 2em;
      }
    }
    .music-player-timeline {
      padding-top: 1em;
      .seekbar {
        width: 100%
      }
    }
  }

  .shout-window-container {
    height: 100%;
  }
  .music-panel {
    .title {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
