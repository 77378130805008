import FollowCard from '../follow_card/follow_card.vue'
import Conversation from '../conversation/conversation.vue'
import Status from '../status/status.vue'
import TabSwitcher from 'src/components/tab_switcher/tab_switcher.jsx'
import EmojiInput from '../emoji_input/emoji_input.vue'
import suggestor from '../emoji_input/suggestor.js'
import map from 'lodash/map'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCircleNotch,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { uniqBy } from 'lodash'

library.add(
  faCircleNotch,
  faSearch
)

const Search = {
  components: {
    FollowCard,
    Conversation,
    Status,
    EmojiInput,
    TabSwitcher
  },
  props: [
    'query'
  ],
  data () {
    return {
      loaded: false,
      loading: false,
      emojiInputShown: false,
      searchTerm: this.query || '',
      searchType: null,
      searchAccount: null,
      searchTypes: [
        { name: 'nav.search_types.all', value: null },
        { name: 'nav.search_types.accounts', value: 'accounts' },
        { name: 'nav.search_types.hashtags', value: 'hashtags' },
        { name: 'nav.search_types.statuses', value: 'statuses' }
      ],
      userIds: [],
      statuses: [],
      hashtags: [],
      currenResultTab: 'statuses',

      statusesOffset: 0,
      failedQueriesCount: 0,
      lastQuery: '',
      lastAccount: '',
      lastType: ''
    }
  },
  computed: {
    users () {
      return this.userIds.map(userId => this.$store.getters.findUser(userId))
    },
    visibleStatuses () {
      const allStatusesObject = this.$store.state.statuses.allStatusesObject

      return this.statuses.filter(status =>
        allStatusesObject[status.id] && !allStatusesObject[status.id].deleted
      )
    },
    emojiUserSuggestor () {
      return suggestor({
        emoji: [
          ...this.$store.getters.standardEmojiList,
          ...this.$store.state.instance.customEmoji
        ],
        store: this.$store
      })
    }
  },
  mounted () {
    this.search(this.query)
  },
  watch: {
    query (newValue) {
      this.searchTerm = newValue
      this.search(newValue, this.$route.query.type)
    }
  },
  methods: {
    onEmojiInputInput (e) {
      this.$nextTick(() => {
        this.resize(this.$refs.textarea)
      })
    },
    handleEmojiInputShow (value) {
      this.emojiInputShown = value
    },
    showEmojiPicker () {
      this.$refs.textarea.focus()
      this.$refs['emoji-input'].triggerShowPicker()
    },
    newQuery (query, type) {
      if (query === this.searchTerm) {
        this.search(query, this.$route.query.type)
      }
      this.$router.push({ name: 'search', query: { query, type } })
      this.$refs.searchInput.focus()
    },
    search (query, searchType, initialSearch = true) {
      if (!query) {
        this.loading = false
        return
      }

      this.loading = true
      this.$refs.searchInput.blur()
      if (initialSearch) {
        if (this.lastQuery !== query || this.lastAccount !== this.searchAccount || this.lastType !== searchType) {
          this.userIds = []
          this.hashtags = []
          this.statuses = []

          this.statusesOffset = 0
        }
      }
      const searchAccount = this.searchAccount
      this.$store.dispatch('search', { q: query, resolve: true, offset: this.statusesOffset, type: searchType, accountId: searchAccount })
        .then(data => {
          this.loading = false

          // Always append to old results. If new results are empty, this doesn't change anything
          this.userIds = this.userIds.concat(map(data.accounts, 'id'))
          this.statuses = uniqBy(this.statuses.concat(data.statuses), 'id')
          this.hashtags = this.hashtags.concat(data.hashtags)

          this.statuses.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at)
          })

          this.currenResultTab = this.getActiveTab()
          this.loaded = true

          // Offset from whatever we already have
          this.statusesOffset = this.statuses.length
          // Because Elasticsearch (or at least Varis :hinata_acid:) has a weird thing of skipping statuses randomly, for a certain offset
          // this is the easiest way to get around it. If it gets no statuses for 3 searches/load-mores it stops showing load more
          if (data.statuses.length === 0) {
            this.failedQueriesCount++
            this.statusesOffset += 20 // Hardcoded, might change later uwu
          }
          console.log(this.failedQueriesCount)
          console.log(data.statuses.length)
          this.lastQuery = query
        })
    },
    resultCount (tabName) {
      const length = this[tabName].length
      return length === 0 ? '' : ` (${length})`
    },
    onResultTabSwitch (key) {
      this.currenResultTab = key
    },
    getActiveTab () {
      if (this.visibleStatuses.length > 0) {
        return 'statuses'
      } else if (this.users.length > 0) {
        return 'people'
      } else if (this.hashtags.length > 0) {
        return 'hashtags'
      }

      return 'statuses'
    },
    lastHistoryRecord (hashtag) {
      return hashtag.history && hashtag.history[0]
    }
  }
}

export default Search
