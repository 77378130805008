<template>
  <div class="Invites panel panel-default"  v-if="canCreateInvites">
    <div class="panel-heading">
      <div class="title">
        {{ $t('nav.invites') }}
      </div>
    </div>
    <button
        class="btn button-default generate-token-btn"
        @click="generateToken"
      >
        {{ $t('invites.generate_token') }}
      </button>
    <div class="panel-body">
      <table class="invites-table">
        <tr>
          <th>{{ $t('invites.id') }}</th>
          <th>{{ $t('invites.token') }}</th>
          <th>{{ $t('invites.maxUses') }}</th>
          <th>{{ $t('invites.uses') }}</th>
          <th>{{ $t('invites.used') }}</th>
        </tr>
        <Invite
          v-for="invite in invites"
          :key="invite.id"
          :id="invite.id"
          :token="invite.token"
          :expiresAt="invite.expires_at"
          :maxUse="invite.max_use"
          :uses="invite.uses"
          :used="invite.used"
        />
      </table>
    </div>
  </div>
</template>

<script src="./invites.js"></script>
<style lang="scss">
.Invites {
.generate-token-btn {
  margin: 1em 2em;
}
.invites-table {
  position: relative;
  width:100%;
  table-layout:fixed;
}
}
</style>
