const musicPlayer = {
  state: {
    currentSong: null,
    songState: null,
    playlist: [],
    playlistIndex: null,
    volume: 0
  },
  mutations: {
    incrementPlaylistIndex (state) {
      if (state.playlistIndex >= state.playlist.length - 1) { return }
      state.playlistIndex++
    },
    decrimentPlaylistIndex (state) {
      if (state.playlistIndex <= 0) { return }
      state.playlistIndex--
    },
    setPlaylist (state, value) {
      if (!value.append) {
        state.playlist = []
        state.playlistIndex = 0
      }
      state.playlist = value.list
      this.commit('setSong', state.playlist[state.playlistIndex])
    },
    setSong (state, value) {
      if (state.currentSong != null) {
        state.currentSong.pause()
        delete state.currentSong
      }

      state.currentSong = new Audio(value)
    },
    setSongVolume (state, value) {
      state.volume = value
      state.currentSong.volume = state.volume / 100
    },
    setSongPosition (state, value) {
      state.currentSong.currentTime = value * state.currentSong.duration / 100
    },
    setSongState (state, value) {
      if (state.currentSong === null) { return }
      state.songState = value
      if (state.songState === true) {
        state.currentSong.play()
          .catch(() => {
            state.songState = false
          })
      } else {
        state.currentSong.pause()
      }
    }
  },
  actions: {
    setBackgroundMusic ({ commit, getters }, value) {
      commit('setSong', value)
      commit('setSongVolume', getters.mergedConfig.defaultProfileMusicVolume)
    },
    setSongVolume ({ commit }, value) {
      commit('setSongVolume', value)
    },
    setSongState ({ commit }, value) {
      commit('setSongState', value)
    },
    setPlaylist ({ commit }, value) {
      commit('setPlaylist', value)
    },
    setSongPosition ({ commit }, value) {
      commit('setSongPosition', value)
    },
    playlistNext ({ commit, getters }) {
      commit('incrementPlaylistIndex')
      commit('setSong', getters.getPlaylist[getters.getPlaylistIndex])
      commit('setSongState', true)
      commit('setSongVolume', getters.getVolume)
    },
    playlistPrev ({ commit, getters }) {
      commit('decrimentPlaylistIndex')
      commit('setSong', getters.getPlaylist[getters.getPlaylistIndex])
      commit('setSongState', true)
      commit('setSongVolume', getters.getVolume)
    }
  },
  getters: {
    songState (state) {
      return state.songState
    },
    songProgress (state) {
      return Math.round(state.currentSong.currentTime / state.currentSong.duration * 100)
    },
    hasSong (state) {
      return state.currentSong
    },
    getPlaylistIndex (state) {
      return state.playlistIndex
    },
    getPlaylist (state) {
      return state.playlist
    },
    getVolume (state) {
      return state.volume
    }
  }
}

export default musicPlayer
