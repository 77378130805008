import { mapState, mapGetters, mapActions } from 'vuex'
import Invite from './invite.vue'

const Invites = {
  data () {
    return {
      invites: []
    }
  },
  components: {
    Invite
  },
  computed: {
    ...mapState({
      currentUser: state => state.users.currentUser
    }),
    ...mapGetters([
      'fetchInvites'
    ]),
    canCreateInvites () {
      if (this.currentUser.privileges.includes('users_manage_invites')) {
        this.getInvites()
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions(['createNewToken']),
    getInvites () {
      this.fetchInvites
        .then((data) => {
          // Sort by newest
          this.invites = data.invites.sort(function (a, b) {
            return b.id - a.id
          })
        })
    },
    generateToken () {
      this.createNewToken()
        .then(() => {
          this.getInvites()
        })
    }
  }
}

export default Invites
