const resource = {
  "about": {
    "mrf": {
      "federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Federation"])},
      "keyword": {
        "keyword_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword policies"])},
        "ftl_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removal from \"The Whole Known Network\" Timeline"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
        "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace"])},
        "is_replaced_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["→"])}
      },
      "mrf_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabled MRF policies"])},
      "mrf_policies_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRF policies manipulate the federation behaviour of the instance.  The following policies are enabled:"])},
      "simple": {
        "simple_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance-specific policies"])},
        "instance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instance"])},
        "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
        "not_applicable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
        "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
        "accept_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance only accepts messages from the following instances:"])},
        "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
        "reject_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance will not accept messages from the following instances:"])},
        "quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarantine"])},
        "quarantine_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance will send only public posts to the following instances:"])},
        "ftl_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removal from \"Known Network\" Timeline"])},
        "ftl_removal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance removes these instances from \"Known Network\" timeline:"])},
        "media_removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Removal"])},
        "media_removal_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance removes media from posts on the following instances:"])},
        "media_nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media force-set as sensitive"])},
        "media_nsfw_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance forces media to be set sensitive in posts on the following instances:"])}
      }
    },
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staff"])}
  },
  "announcements": {
    "page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
    "mark_as_read_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
    "post_form_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post announcement"])},
    "post_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your announcement content here..."])},
    "post_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "post_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error: ", _interpolate(_named("error"))])},
    "close_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "delete_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "start_time_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start time: "])},
    "end_time_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End time: "])},
    "all_day_prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an all-day event"])},
    "published_time_display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Published at ", _interpolate(_named("time"))])},
    "start_time_display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starts at ", _interpolate(_named("time"))])},
    "end_time_display": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ends at ", _interpolate(_named("time"))])},
    "edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "submit_edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "cancel_edit_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "inactive_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This announcement is inactive"])}
  },
  "shoutbox": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cunnybox"])}
  },
  "music_player": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music Player"])}
  },
  "invites": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
    "used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
    "uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uses"])},
    "maxUses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Uses"])},
    "expires_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires At"])},
    "copy_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
    "generate_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new token"])}
  },
  "domain_mute_card": {
    "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute"])},
    "mute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muting…"])},
    "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute"])},
    "unmute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmuting…"])}
  },
  "exporter": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing, you'll soon be asked to download your file"])}
  },
  "features_panel": {
    "shout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cunnybox"])},
    "pleroma_chat_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleroma Chat"])},
    "gopher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gopher"])},
    "media_proxy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media proxy"])},
    "scope_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope options"])},
    "text_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text limit"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "who_to_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to follow"])},
    "upload_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload limit"])}
  },
  "finder": {
    "error_fetching_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching user"])},
    "find_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find user"])}
  },
  "general": {
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading…"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured"])},
    "generic_error_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["An error occured: ", _interpolate(_list(0))])},
    "error_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
    "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "never_show_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never show again"])},
    "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "peek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peek"])},
    "scroll_to_top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll to top"])},
    "role": {
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
      "moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator"])}
    },
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpin item"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin item"])},
    "flash_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to show Flash content using Ruffle (Experimental, may not work)."])},
    "flash_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that this can be potentially dangerous since Flash content is still arbitrary code."])},
    "flash_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load flash content, see console for details."])},
    "scope_in_timeline": {
      "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers-only"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
      "unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlisted"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local"])}
    }
  },
  "image_cropper": {
    "crop_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crop picture"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "save_without_cropping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save without cropping"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "importer": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported successfully."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while importing this file."])}
  },
  "login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with OAuth"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Halo (But Not As Cool)"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to join the discussion"])},
    "authentication_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication code"])},
    "enter_recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a recovery code"])},
    "enter_two_factor_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a two-factor code"])},
    "recovery_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery code"])},
    "heading": {
      "totp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor authentication"])},
      "recovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor recovery"])}
    }
  },
  "media_modal": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "counter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("current")), " / ", _interpolate(_named("total"))])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close media viewer"])}
  },
  "nav": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "friend_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow requests"])},
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "interactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactions"])},
    "invites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invites"])},
    "dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct messages"])},
    "public_tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public timeline"])},
    "timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])},
    "home_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home timeline"])},
    "twkn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known Network"])},
    "bookmarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmarks"])},
    "user_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Search"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "search_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Type"])},
    "search_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account to search from"])},
    "search_types": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuses"])},
      "accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
      "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])}
    },
    "who_to_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to follow"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
    "timelines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timelines"])},
    "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
    "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
    "edit_nav_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize navigation bar"])},
    "edit_pinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit pinned items"])},
    "edit_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done editing"])},
    "mobile_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle mobile sidebar"])},
    "mobile_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open notifications"])},
    "mobile_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open notifications (there are unread ones)"])},
    "mobile_notifications_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close notifications"])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcements"])}
  },
  "notifications": {
    "broken_favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown status, searching for it…"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error fetching notifications: ", _interpolate(_list(0))])},
    "favorited_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["favorited your status"])},
    "followed_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["followed you"])},
    "follow_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wants to follow you"])},
    "load_older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load older notifications"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read!"])},
    "repeated_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repeated your status"])},
    "no_more_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more notifications"])},
    "migrated_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["migrated to"])},
    "reacted_with": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["reacted with ", _interpolate(_list(0))])},
    "submitted_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitted a report"])},
    "poll_ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poll has ended"])}
  },
  "polls": {
    "add_poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add poll"])},
    "add_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option"])},
    "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
    "votes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votes"])},
    "people_voted_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " person voted"]), _normalize([_interpolate(_named("count")), " people voted"])])},
    "votes_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " vote"]), _normalize([_interpolate(_named("count")), " votes"])])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll type"])},
    "single_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single choice"])},
    "multiple_choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple choices"])},
    "expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll age"])},
    "expires_in": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poll ends in ", _interpolate(_list(0))])},
    "expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Poll ended ", _interpolate(_list(0)), " ago"])},
    "not_enough_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too few unique options in poll"])}
  },
  "emoji": {
    "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stickers"])},
    "emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji"])},
    "keep_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep picker open"])},
    "search_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an emoji"])},
    "add_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert emoji"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom emoji"])},
    "unpacked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpacked emoji"])},
    "unicode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unicode emoji"])},
    "unicode_groups": {
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
      "animals-and-nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animals & Nature"])},
      "flags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flags"])},
      "food-and-drink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food & Drink"])},
      "objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects"])},
      "people-and-body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People & Body"])},
      "smileys-and-emotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys & Emotion"])},
      "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])},
      "travel-and-places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel & Places"])}
    },
    "load_all_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Loaded first ", _interpolate(_named("saneAmount")), " emoji, loading all emoji may cause performance issues."])},
    "load_all": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Loading all ", _interpolate(_named("emojiAmount")), " emoji"])},
    "regional_indicator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Regional indicator ", _interpolate(_named("letter"))])}
  },
  "errors": {
    "storage_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleroma could not access browser storage. Your login or your local settings won't be saved and you might encounter unexpected issues. Try enabling cookies."])}
  },
  "interactions": {
    "favs_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats and favorites"])},
    "follows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New follows"])},
    "emoji_reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji Reactions"])},
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User migrates"])},
    "load_older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load older interactions"])}
  },
  "post_status": {
    "edit_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit status"])},
    "new_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post new status"])},
    "account_not_locked_warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Your account is not ", _interpolate(_list(0)), ". Anyone can follow you to view your follower-only posts."])},
    "account_not_locked_warning_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["locked"])},
    "attachments_sensitive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark attachments as sensitive"])},
    "media_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media description"])},
    "content_type": {
      "text/plain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plain text"])},
      "text/html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML"])},
      "text/markdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markdown"])},
      "text/bbcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BBCode"])}
    },
    "content_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject - Content Warning (optional)"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Made A Baby With My Loli Wife"])},
    "direct_warning_to_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will be visible to all the mentioned users."])},
    "direct_warning_to_first_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will only be visible to the mentioned users at the beginning of the message."])},
    "edit_remote_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other remote instances may not support editing and unable to receive the latest version of your post."])},
    "edit_unsupported_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleroma does not support editing mentions or polls."])},
    "posting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posting"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
    "preview_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
    "empty_status_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't post an empty status with no files"])},
    "media_description_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update media, try again"])},
    "scope_notice": {
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will be visible to everyone"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will be visible to your followers only"])},
      "unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will not be visible in Public Timeline and The Whole Known Network"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post will only be visibile to your instance"])}
    },
    "scope": {
      "direct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct - post to mentioned users only"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers-only - post to followers only"])},
      "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public - post to public timelines"])},
      "unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlisted - do not post to public timelines"])},
      "local": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local - only visible to your instance"])}
    }
  },
  "registration": {
    "bio_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio (optional)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "email_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email (optional)"])},
    "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display name"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password confirmation"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite token"])},
    "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPTCHA"])},
    "new_captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the image to get a new captcha"])},
    "username_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Shinobu"])},
    "fullname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. Oshino Shinobu"])},
    "bio_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g.\nHi, I'm Shinobu.\nI’m an anime girl living in suburban Japan. I love donuts"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason to register"])},
    "reason_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instance approves registrations manually.\nLet the administration know why you want to register."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "validations": {
      "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "fullname_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "email_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "password_confirmation_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "password_confirmation_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["should be the same as password"])},
      "birthday_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cannot be left blank"])},
      "birthday_min_age": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["must be on or before ", _interpolate(_named("date"))])}
    },
    "email_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In which language do you want to receive emails from the server?"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday:"])},
    "birthday_optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday (optional):"])}
  },
  "remote_user_resolver": {
    "remote_user_resolver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote user resolver"])},
    "searching_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found."])}
  },
  "report": {
    "reporter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporter:"])},
    "reported_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported user:"])},
    "reported_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported statuses:"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes:"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State:"])},
    "state_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "state_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "state_resolved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolved"])}
  },
  "selectable_list": {
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])}
  },
  "settings": {
    "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App name"])},
    "expert_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show advanced"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "setting_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting is different from default"])},
    "setting_server_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This setting is tied to your profile and affects all sessions and clients"])},
    "enter_current_password_to_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your current password to confirm your identity"])},
    "post_look_feel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts Look & Feel"])},
    "plf": {
      "profile_look_feel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Look & Feel"])},
      "allow_custom_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow custom profiles"])},
      "allow_profile_music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow a profiles music to autoplay"])},
      "allow_profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow a profiles background to display"])},
      "pause_profile_music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue music after exiting profile?"])},
      "profile_music_volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Volume"])}
    },
    "mention_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention links"])},
    "mfa": {
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
      "setup_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup OTP"])},
      "wait_pre_setup_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presetting OTP"])},
      "confirm_and_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm & enable OTP"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-factor Authentication"])},
      "generate_new_recovery_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new recovery codes"])},
      "warning_of_generate_new_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you generate new recovery codes, your old codes won’t work anymore."])},
      "recovery_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery codes."])},
      "waiting_a_recovery_codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving backup codes…"])},
      "recovery_codes_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the codes down or save them somewhere secure - otherwise you won't see them again. If you lose access to your 2FA app and recovery codes you'll be locked out of your account."])},
      "authentication_methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication methods"])},
      "scan": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using your two-factor app, scan this QR code or enter text key:"])},
        "secret_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])}
      },
      "verify": {
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enable two-factor authentication, enter the code from your two-factor app:"])}
      }
    },
    "lists_navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show lists in navigation"])},
    "allow_following_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow auto-follow when following account moves"])},
    "attachmentRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
    "avatarAltRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars (notifications)"])},
    "avatarRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatars"])},
    "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background"])},
    "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "email_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language for receiving emails from the server"])},
    "block_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block export"])},
    "block_export_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export your blocks to a csv file"])},
    "block_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block import"])},
    "block_import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error importing blocks"])},
    "blocks_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks imported! Processing them will take a while."])},
    "mute_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute export"])},
    "mute_export_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export your mutes to a csv file"])},
    "mute_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute import"])},
    "mute_import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error importing mutes"])},
    "mutes_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutes imported! Processing them will take a while."])},
    "import_mutes_from_a_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import mutes from a csv file"])},
    "account_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account backup"])},
    "account_backup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This allows you to download an archive of your account information and your posts, but they cannot yet be imported into a Pleroma account."])},
    "account_backup_table_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
    "download_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "backup_not_ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This backup is not ready yet."])},
    "backup_running": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["This backup is in progress, processed ", _interpolate(_named("number")), " record."]), _normalize(["This backup is in progress, processed ", _interpolate(_named("number")), " records."])])},
    "backup_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This backup has failed."])},
    "remove_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "list_backups_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error fetching backup list: ", _interpolate(_named("error"))])},
    "add_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new backup"])},
    "added_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added a new backup."])},
    "add_backup_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error adding a new backup: ", _interpolate(_named("error"))])},
    "blocks_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks"])},
    "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a bot account"])},
    "btnRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
    "cBlue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue (Reply, follow)"])},
    "cGreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green (Retweet)"])},
    "cOrange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange (Favorite)"])},
    "cRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red (Cancel)"])},
    "change_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email"])},
    "change_email_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue changing your email."])},
    "changed_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email changed successfully!"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "change_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue changing your password."])},
    "changed_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully!"])},
    "chatMessageRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat message"])},
    "collapse_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse posts with subjects"])},
    "composing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composing"])},
    "confirm_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "mutes_and_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutes and Blocks"])},
    "data_import_export_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data import / export"])},
    "default_vis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default visibility scope"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanently delete your data and deactivate your account."])},
    "delete_account_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an issue deleting your account. If this persists please contact your instance administrator."])},
    "delete_account_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your password in the input below to confirm account deletion."])},
    "account_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aliases"])},
    "account_alias_table_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
    "list_aliases_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error fetching aliases: ", _interpolate(_named("error"))])},
    "hide_list_aliases_error_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "remove_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove this alias"])},
    "new_alias_target": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add a new alias (e.g. ", _interpolate(_named("example")), ")"])},
    "added_alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias is added."])},
    "add_alias_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error adding alias: ", _interpolate(_named("error"))])},
    "move_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move account"])},
    "move_account_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to move the account somewhere else, you must go to your target account and add an alias pointing here."])},
    "move_account_target": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Target account (e.g. ", _interpolate(_named("example")), ")"])},
    "moved_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is moved."])},
    "move_account_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error moving account: ", _interpolate(_named("error"))])},
    "discoverable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow discovery of this account in search results and other services"])},
    "domain_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domains"])},
    "avatar_size_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The recommended minimum size for avatar images is 150x150 pixels."])},
    "pad_emoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pad emoji with spaces when adding from picker"])},
    "emoji_reactions_on_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show emoji reactions on timeline"])},
    "export_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save preset"])},
    "filtering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtering"])},
    "wordfilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordfilter"])},
    "filtering_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All statuses containing these words will be muted, one per line"])},
    "word_filter_and_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Word filter and more..."])},
    "follow_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow export"])},
    "follow_export_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export your follows to a csv file"])},
    "follow_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow import"])},
    "follow_import_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error importing followers"])},
    "follows_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows imported! Processing them will take a while."])},
    "accent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accent"])},
    "foreground": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreground"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "hide_attachments_in_convo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachments in conversations"])},
    "hide_attachments_in_tl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachments in timeline"])},
    "hide_media_previews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide media previews"])},
    "hide_muted_posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide posts of muted users"])},
    "mute_bot_posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute bot posts"])},
    "hide_bot_indication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide bot indication in posts"])},
    "hide_all_muted_posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide muted posts"])},
    "max_thumbnails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount of thumbnails per post (empty = no limit)"])},
    "hide_isp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance-specific panel"])},
    "hide_shoutbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance cunnybox"])},
    "right_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse order of columns"])},
    "navbar_column_stretch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stretch navbar to columns width"])},
    "always_show_post_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always show floating New Post button"])},
    "hide_wallpaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide instance wallpaper"])},
    "preload_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preload images"])},
    "use_one_click_nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open NSFW attachments with just one click"])},
    "hide_post_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide post statistics (e.g. the number of favorites)"])},
    "hide_user_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide user statistics (e.g. the number of followers)"])},
    "hide_filtered_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide all filtered posts"])},
    "hide_wordfiltered_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide word-filtered statuses"])},
    "hide_muted_threads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide muted threads"])},
    "import_blocks_from_a_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import blocks from a csv file"])},
    "import_followers_from_a_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import follows from a csv file"])},
    "import_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load preset"])},
    "inputRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fields"])},
    "checkboxRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkboxes"])},
    "instance_default": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(default: ", _interpolate(_named("value")), ")"])},
    "instance_default_simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(default)"])},
    "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
    "interfaceLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface language"])},
    "invalid_theme_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected file is not a supported Pleroma theme. No changes to your theme were made."])},
    "limited_availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable in your browser"])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "lock_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrict your account to approved followers only"])},
    "loop_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loop videos"])},
    "loop_video_silent_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loop only videos without sound (i.e. Mastodon's \"gifs\")"])},
    "mutes_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutes"])},
    "play_videos_in_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play videos in a popup frame"])},
    "file_export_import": {
      "backup_restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings backup"])},
      "backup_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup settings to file"])},
      "backup_settings_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup settings and theme to file"])},
      "restore_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore settings from file"])},
      "errors": {
        "invalid_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected file is not a supported Pleroma settings backup. No changes were made."])},
        "file_too_new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Incompatile major version: ", _interpolate(_named("fileMajor")), ", this PleromaFE (settings ver ", _interpolate(_named("feMajor")), ") is too old to handle it"])},
        "file_too_old": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Incompatile major version: ", _interpolate(_named("fileMajor")), ", file version is too old and not supported (min. set. ver. ", _interpolate(_named("feMajor")), ")"])},
        "file_slightly_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File minor version is different, some settings might not load"])}
      }
    },
    "profile_fields": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile metadata"])},
      "add_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add field"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])}
    },
    "birthday": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "show_birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show my birthday"])}
    },
    "account_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "use_contain_fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't crop the attachment in thumbnails"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "name_bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name & bio"])},
    "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
    "user_profiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profiles"])},
    "notification_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types of notifications to show"])},
    "notification_visibility_follows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows"])},
    "notification_visibility_likes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "notification_visibility_mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "notification_visibility_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats"])},
    "notification_visibility_moves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Migrates"])},
    "notification_visibility_emoji_reactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactions"])},
    "notification_visibility_polls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends of polls you voted in"])},
    "no_rich_text_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strip rich text formatting from all posts"])},
    "no_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No blocks"])},
    "no_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mutes"])},
    "hide_favorites_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show list of my favorites (people still get notified)"])},
    "hide_follows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show who I'm following"])},
    "hide_followers_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show who's following me"])},
    "hide_follows_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show follow count"])},
    "hide_followers_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show follower count"])},
    "show_admin_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show \"Admin\" badge in my profile"])},
    "show_moderator_badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show \"Moderator\" badge in my profile"])},
    "nsfw_clickthrough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide sensitive/NSFW media"])},
    "oauth_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth tokens"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
    "refresh_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh token"])},
    "valid_until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
    "revoke_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
    "panelRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panels"])},
    "pause_on_unfocused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause when tab is not focused"])},
    "presets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presets"])},
    "profile_customisation": {
      "customise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile customisation"])},
      "profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile background"])},
      "profile_music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile music"])}
    },
    "profile_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile banner"])},
    "profile_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "radii_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up interface edge rounding (in pixels)"])},
    "replies_in_timeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies in timeline"])},
    "reply_visibility_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all replies"])},
    "reply_visibility_following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show replies directed at me or users I'm following"])},
    "reply_visibility_self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show replies directed at me"])},
    "reply_visibility_following_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show replies to my follows"])},
    "reply_visibility_self_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show replies to self only"])},
    "autohide_floating_post_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically hide New Post button (mobile)"])},
    "saving_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving settings"])},
    "saving_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings saved"])},
    "search_user_to_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search whom you want to block"])},
    "search_user_to_mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search whom you want to mute"])},
    "security_tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "scope_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy scope when replying (DMs are always copied)"])},
    "minimal_scopes_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimize post scope selection options"])},
    "set_new_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new avatar"])},
    "set_new_profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new profile background"])},
    "set_new_profile_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new profile banner"])},
    "set_new_profile_music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new profile music"])},
    "reset_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset avatar"])},
    "reset_profile_background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset profile background"])},
    "reset_profile_banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset profile banner"])},
    "reset_avatar_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the avatar?"])},
    "reset_banner_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the banner?"])},
    "reset_background_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to reset the background?"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "subject_input_always_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always show subject field"])},
    "subject_line_behavior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy subject when replying"])},
    "subject_line_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like email: \"re: subject\""])},
    "subject_line_mastodon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like mastodon: copy as is"])},
    "subject_line_noop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not copy"])},
    "conversation_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation display style"])},
    "conversation_display_tree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree-style"])},
    "conversation_display_tree_quick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree view"])},
    "disable_sticky_headers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't stick column headers to top of the screen"])},
    "show_scrollbars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show side column's scrollbars"])},
    "third_column_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When there's enough space, show third column containing"])},
    "third_column_mode_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show third column at all"])},
    "third_column_mode_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications column"])},
    "third_column_mode_postform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main post form and navigation"])},
    "columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
    "column_sizes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column sizes"])},
    "column_sizes_sidebar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidebar"])},
    "column_sizes_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "column_sizes_notifs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "tree_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow more flexible navigation in tree view"])},
    "tree_fade_ancestors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display ancestors of the current status in faint text"])},
    "conversation_display_linear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear-style"])},
    "conversation_display_linear_quick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear view"])},
    "conversation_other_replies_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the \"other replies\" button"])},
    "conversation_other_replies_button_below": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below statuses"])},
    "conversation_other_replies_button_inside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inside statuses"])},
    "max_depth_in_thread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of levels in thread to display by default"])},
    "post_status_content_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post status content type"])},
    "sensitive_by_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark posts as sensitive by default"])},
    "stop_gifs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause animated images until you hover on them"])},
    "streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically show new posts when scrolled to the top"])},
    "auto_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show new posts automatically"])},
    "user_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "useStreamingApi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive posts and notifications real-time"])},
    "use_websockets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use websockets (Realtime updates)"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
    "theme_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use hex color codes (#rrggbb) to customize your color theme."])},
    "theme_help_v2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also override certain component's colors and opacity by toggling the checkbox, use \"Clear all\" button to clear all overrides."])},
    "theme_help_v2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons underneath some entries are background/text contrast indicators, hover over for detailed info. Please keep in mind that when using transparency contrast indicators show the worst possible case."])},
    "tooltipRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltips/alerts"])},
    "type_domains_to_mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search domains to mute"])},
    "upload_a_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a photo"])},
    "user_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Settings"])},
    "values": {
      "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
      "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
    },
    "virtual_scrolling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimize timeline rendering"])},
    "use_at_icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display ", "@", " symbol as an icon instead of text"])},
    "mention_link_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display mention links"])},
    "mention_link_display_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["always as short names (e.g. ", "@", "foo)"])},
    "mention_link_display_full_for_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as full names only for remote users (e.g. ", "@", "foo", "@", "example.org)"])},
    "mention_link_display_full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["always as full names (e.g. ", "@", "foo", "@", "example.org)"])},
    "mention_link_use_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show user card when clicking mention links"])},
    "mention_link_show_avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show user avatar beside the link"])},
    "mention_link_show_avatar_quick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show user avatar next to mentions"])},
    "mention_link_fade_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fade domains (e.g. ", "@", "example.org in ", "@", "foo", "@", "example.org)"])},
    "mention_link_bolden_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight mention of you when you are mentioned"])},
    "user_popover_avatar_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popover avatar click action"])},
    "user_popover_avatar_action_zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom the avatar"])},
    "user_popover_avatar_action_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the popover"])},
    "user_popover_avatar_action_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open profile"])},
    "user_popover_avatar_overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show user popover over user avatar"])},
    "fun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fun"])},
    "greentext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meme arrows"])},
    "show_yous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show (You)s"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "notification_setting_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "notification_setting_block_from_strangers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block notifications from users who you do not follow"])},
    "notification_setting_privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "notification_setting_hide_notification_contents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide the sender and contents of push notifications"])},
    "notification_mutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To stop receiving notifications from a specific user, use a mute."])},
    "notification_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking a user stops all notifications as well as unsubscribes them."])},
    "enable_web_push_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable web push notifications"])},
    "more_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More settings"])},
    "style": {
      "switcher": {
        "keep_color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep colors"])},
        "keep_shadows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep shadows"])},
        "keep_opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep opacity"])},
        "keep_roundness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep roundness"])},
        "keep_fonts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep fonts"])},
        "save_load_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Keep\" options preserve currently set options when selecting or loading themes, it also stores said options when exporting a theme. When all checkboxes unset, exporting theme will save everything."])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "clear_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
        "clear_opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear opacity"])},
        "load_theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load theme"])},
        "keep_as_is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep as is"])},
        "use_snapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old version"])},
        "use_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version"])},
        "help": {
          "upgraded_from_v2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PleromaFE has been upgraded, theme could look a little bit different than you remember."])},
          "v2_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File you imported was made for older FE. We try to maximize compatibility but there still could be inconsistencies."])},
          "future_version_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File you imported was made in newer version of FE."])},
          "older_version_imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File you imported was made in older version of FE."])},
          "snapshot_present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme snapshot is loaded, so all values are overriden. You can load theme's actual data instead."])},
          "snapshot_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No theme snapshot was in the file so it could look different than originally envisioned."])},
          "fe_upgraded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PleromaFE's theme engine upgraded after version update."])},
          "fe_downgraded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PleromaFE's version rolled back."])},
          "migration_snapshot_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just to be safe, theme snapshot loaded. You can try loading theme data."])},
          "migration_napshot_gone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For whatever reason snapshot was missing, some stuff could look different than you remember."])},
          "snapshot_source_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versions conflict: most likely FE was rolled back and updated again, if you changed theme using older version of FE you most likely want to use old version, otherwise use new version."])}
        }
      },
      "common": {
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
        "opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opacity"])},
        "contrast": {
          "hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contrast ratio is ", _interpolate(_named("ratio")), ", it ", _interpolate(_named("level")), " ", _interpolate(_named("context"))])},
          "level": {
            "aa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meets Level AA guideline (minimal)"])},
            "aaa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meets Level AAA guideline (recommended)"])},
            "bad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doesn't meet any accessibility guidelines"])}
          },
          "context": {
            "18pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for large (18pt+) text"])},
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for text"])}
          }
        }
      },
      "common_colors": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common"])},
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common colors"])},
        "foreground_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See \"Advanced\" tab for more detailed control"])},
        "rgbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons, accents, badges"])}
      },
      "advanced_colors": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
        "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert background"])},
        "alert_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
        "alert_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
        "alert_neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])},
        "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts/User bios"])},
        "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge background"])},
        "popover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tooltips, menus, popovers"])},
        "badge_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
        "panel_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel header"])},
        "top_bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top bar"])},
        "borders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borders"])},
        "buttons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buttons"])},
        "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fields"])},
        "faint_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faded text"])},
        "underlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Underlay"])},
        "wallpaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallpaper"])},
        "poll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poll graph"])},
        "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icons"])},
        "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlighted elements"])},
        "pressed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressed"])},
        "selectedPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected post"])},
        "selectedMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected menu item"])},
        "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])},
        "toggled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggled"])},
        "tabs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabs"])},
        "chat": {
          "incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming"])},
          "outgoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing"])},
          "border": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Border"])}
        }
      },
      "radii": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roundness"])}
      },
      "shadows": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadow and lighting"])},
        "component": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Component"])},
        "override": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Override"])},
        "shadow_id": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Shadow #", _interpolate(_named("value"))])},
        "blur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blur"])},
        "spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread"])},
        "inset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inset"])},
        "hintV3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["For shadows you can also use the ", _interpolate(_list(0)), " notation to use other color slot."])},
        "filter_hint": {
          "always_drop_shadow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Warning, this shadow always uses ", _interpolate(_list(0)), " when browser supports it."])},
          "drop_shadow_syntax": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " does not support ", _interpolate(_list(1)), " parameter and ", _interpolate(_list(2)), " keyword."])},
          "avatar_inset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that combining both inset and non-inset shadows on avatars might give unexpected results with transparent avatars."])},
          "spread_zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadows with spread > 0 will appear as if it was set to zero"])},
          "inset_classic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Inset shadows will be using ", _interpolate(_list(0))])}
        },
        "components": {
          "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
          "panelHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel header"])},
          "topBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top bar"])},
          "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User avatar (in profile view)"])},
          "avatarStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User avatar (in post display)"])},
          "popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popups and tooltips"])},
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
          "buttonHover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button (hover)"])},
          "buttonPressed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button (pressed)"])},
          "buttonPressedHover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button (pressed+hover)"])},
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input field"])}
        }
      },
      "fonts": {
        "_tab_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonts"])},
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select font to use for elements of UI. For \"custom\" you have to enter exact font name as it appears in system."])},
        "components": {
          "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
          "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input fields"])},
          "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post text"])},
          "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monospaced text in a post (rich text)"])}
        },
        "family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font name"])},
        "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size (in px)"])},
        "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (boldness)"])},
        "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])}
      },
      "preview": {
        "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example error"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button"])},
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A bunch of more ", _interpolate(_list(0)), " and ", _interpolate(_list(1))])},
        "mono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Made A Baby With My Loli Wife"])},
        "faint_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helpful manual"])},
        "fine_print": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Read our ", _interpolate(_list(0)), " to learn nothing useful!"])},
        "header_faint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is fine"])},
        "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have skimmed over terms and conditions"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a nice lil' link"])}
      }
    },
    "version": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
      "backend_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend version"])},
      "frontend_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontend version"])}
    }
  },
  "time": {
    "unit": {
      "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " day"]), _normalize([_interpolate(_list(0)), " days"])])},
      "days_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "d"])},
      "hours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " hour"]), _normalize([_interpolate(_list(0)), " hours"])])},
      "hours_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "h"])},
      "minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " minute"]), _normalize([_interpolate(_list(0)), " minutes"])])},
      "minutes_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "min"])},
      "months": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " month"]), _normalize([_interpolate(_list(0)), " months"])])},
      "months_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "mo"])},
      "seconds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " second"]), _normalize([_interpolate(_list(0)), " seconds"])])},
      "seconds_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "s"])},
      "weeks": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " week"]), _normalize([_interpolate(_list(0)), " weeks"])])},
      "weeks_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "w"])},
      "years": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_list(0)), " year"]), _normalize([_interpolate(_list(0)), " years"])])},
      "years_short": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "y"])}
    },
    "in_future": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["in ", _interpolate(_list(0))])},
    "in_past": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " ago"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["just now"])},
    "now_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now"])}
  },
  "timeline": {
    "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
    "conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error fetching timeline: ", _interpolate(_list(0))])},
    "load_older": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load older statuses"])},
    "no_retweet_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post is marked as followers-only or direct and cannot be repeated"])},
    "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repeated"])},
    "show_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show new"])},
    "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
    "up_to_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up-to-date"])},
    "no_more_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more statuses"])},
    "no_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No statuses"])},
    "socket_reconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtime connection established"])},
    "socket_broke": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Realtime connection lost: CloseEvent code ", _interpolate(_list(0))])},
    "quick_view_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick view settings"])},
    "quick_filter_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick filter settings"])}
  },
  "status": {
    "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeats"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete status"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit status"])},
    "edited_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(last edited ", _interpolate(_named("time")), ")"])},
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin on profile"])},
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpin from profile"])},
    "pinned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinned"])},
    "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark"])},
    "unbookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbookmark"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this status?"])},
    "reply_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply to"])},
    "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
    "replies_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replies:"])},
    "replies_list_with_others": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Replies (+", _interpolate(_named("numReplies")), " other):"]), _normalize(["Replies (+", _interpolate(_named("numReplies")), " others):"])])},
    "mute_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute conversation"])},
    "unmute_conversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute conversation"])},
    "status_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status unavailable"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link to status"])},
    "external_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External source"])},
    "thread_muted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread muted"])},
    "thread_muted_and_words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", has words:"])},
    "show_full_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show full subject"])},
    "hide_full_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide full subject"])},
    "show_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show content"])},
    "hide_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide content"])},
    "status_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This post was deleted"])},
    "nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NSFW"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(You)"])},
    "plus_more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["+", _interpolate(_named("number")), " more"])},
    "many_attachments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Post has ", _interpolate(_named("number")), " attachment(s)"])},
    "collapse_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse attachments"])},
    "show_all_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all attachments"])},
    "show_attachment_in_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show in media modal"])},
    "show_attachment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview description (open attachment for full description)"])},
    "hide_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide attachment"])},
    "remove_attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove attachment"])},
    "attachment_stop_flash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Flash player"])},
    "move_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift attachment left"])},
    "move_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift attachment right"])},
    "open_gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open gallery"])},
    "thread_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide this thread"])},
    "thread_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this thread"])},
    "thread_show_full": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Show everything under this thread (", _interpolate(_named("numStatus")), " status in total, max depth ", _interpolate(_named("depth")), ")"]), _normalize(["Show everything under this thread (", _interpolate(_named("numStatus")), " statuses in total, max depth ", _interpolate(_named("depth")), ")"])])},
    "thread_show_full_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "thread_follow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["See the remaining part of this thread (", _interpolate(_named("numStatus")), " status in total)"]), _normalize(["See the remaining part of this thread (", _interpolate(_named("numStatus")), " statuses in total)"])])},
    "thread_follow_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "ancestor_follow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["See ", _interpolate(_named("numReplies")), " other reply under this status"]), _normalize(["See ", _interpolate(_named("numReplies")), " other replies under this status"])])},
    "ancestor_follow_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "show_all_conversation_with_icon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("icon")), " ", _interpolate(_named("text"))])},
    "show_all_conversation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Show full conversation (", _interpolate(_named("numStatus")), " other status)"]), _normalize(["Show full conversation (", _interpolate(_named("numStatus")), " other statuses)"])])},
    "show_only_conversation_under_this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only show replies to this status"])},
    "status_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status history"])}
  },
  "user_card": {
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked!"])},
    "deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deny"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
    "follow_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel request"])},
    "follow_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request sent!"])},
    "follow_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting…"])},
    "follow_unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
    "followees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following"])},
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers"])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following!"])},
    "follows_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows you!"])},
    "hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hidden"])},
    "its_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's you!"])},
    "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mute"])},
    "muted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muted"])},
    "per_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per day"])},
    "remote_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote follow"])},
    "remove_follower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove follower"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuses"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
    "unblock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock"])},
    "unblock_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblocking…"])},
    "block_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking…"])},
    "unmute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmute"])},
    "unmute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmuting…"])},
    "mute_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muting…"])},
    "hide_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide repeats"])},
    "show_repeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show repeats"])},
    "bot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bot"])},
    "birthday": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Born ", _interpolate(_named("birthday"))])},
    "admin_menu": {
      "moderation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
      "grant_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant Admin"])},
      "revoke_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke Admin"])},
      "grant_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grant Moderator"])},
      "revoke_moderator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke Moderator"])},
      "activate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
      "deactivate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate account"])},
      "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
      "force_nsfw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all posts as NSFW"])},
      "strip_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove media from posts"])},
      "force_unlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force posts to be unlisted"])},
      "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force posts to be followers-only"])},
      "disable_remote_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disallow following user from remote instances"])},
      "disable_any_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disallow following user at all"])},
      "quarantine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disallow user posts from federating"])},
      "delete_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
      "delete_user_data_and_deactivate_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will permanently delete the data from this account and deactivate it. Are you absolutely sure?"])}
    },
    "highlight": {
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No highlight"])},
      "solid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solid bg"])},
      "striped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Striped bg"])},
      "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side stripe"])}
    },
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "note_blank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(None)"])},
    "edit_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit note"])},
    "edit_note_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "edit_note_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "user_profile": {
    "timeline_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User timeline"])},
    "profile_does_not_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, this profile does not exist."])},
    "profile_loading_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there was an error loading this profile."])}
  },
  "user_reporting": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reporting ", _interpolate(_list(0))])},
    "add_comment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The report will be sent to your instance moderators. You can provide an explanation of why you are reporting this account below:"])},
    "additional_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional comments"])},
    "forward_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account is from another server. Send a copy of the report there as well?"])},
    "forward_to": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Forward to ", _interpolate(_list(0))])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while processing your request."])}
  },
  "who_to_follow": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "who_to_follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to follow"])}
  },
  "tool_tip": {
    "media_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload media"])},
    "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "add_reaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Reaction"])},
    "user_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Settings"])},
    "accept_follow_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept follow request"])},
    "reject_follow_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject follow request"])},
    "bookmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookmark"])},
    "toggle_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand or collapse notification to show post in full"])},
    "toggle_mute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand or collapse notification to reveal muted content"])}
  },
  "upload": {
    "error": {
      "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload failed."])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Upload failed: ", _interpolate(_list(0))])},
      "file_too_big": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File too big [", _interpolate(_named("filesize")), _interpolate(_named("filesizeunit")), " / ", _interpolate(_named("allowedsize")), _interpolate(_named("allowedsizeunit")), "]"])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again later"])}
    },
    "file_size_units": {
      "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      "KiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KiB"])},
      "MiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MiB"])},
      "GiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GiB"])},
      "TiB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiB"])}
    }
  },
  "search": {
    "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
    "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
    "person_talking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " person talking"])},
    "people_talking": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " people talking"])},
    "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "no_more_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more results"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more results"])}
  },
  "password_reset": {
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
    "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address or username. We will send you a link to reset your password."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email or username"])},
    "check_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email for a link to reset your password."])},
    "return_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to the home page"])},
    "too_many_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limit of attempts, try again later."])},
    "password_reset_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset is disabled. Please contact your instance administrator."])},
    "password_reset_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must reset your password to log in."])},
    "password_reset_required_but_mailer_is_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must reset your password, but password reset is disabled. Please contact your instance administrator."])}
  },
  "chats": {
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You:"])},
    "message_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Message ", _interpolate(_named("nickname"))])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Chat"])},
    "empty_message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot post empty message"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to delete this message?"])},
    "error_loading_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when loading the chat."])},
    "error_sending_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong when sending the message."])},
    "empty_chat_list_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any chats yet. Start a new chat!"])}
  },
  "lists": {
    "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New List"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List title"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search users"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete list"])},
    "following_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit to Following"])},
    "manage_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage lists"])},
    "manage_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage list members"])},
    "add_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for more users"])},
    "remove_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from list"])},
    "add_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to list"])},
    "is_in_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already in list"])},
    "editing_list": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editing list ", _interpolate(_named("listTitle"))])},
    "creating_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating new list"])},
    "update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Title"])},
    "really_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Really delete list?"])},
    "error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error manipulating lists: ", _interpolate(_list(0))])}
  },
  "file_type": {
    "audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])}
  },
  "display_date": {
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])}
  },
  "crossover": {
    "big_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Announcement"])},
    "big_update_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm very proud to announce a hard negotiated partnership between Varis.Hangout and ウマ娘 プリティーダービー"])},
    "big_update_extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Between July 8th and July 15th you can use code VAR15 at DMM Games Player to get free 3000 gems, and a guaranteed SSR, go show your support!"])}
  },
  "update": {
    "big_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back gamers!"])},
    "big_update_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We haven't had an FE update in quite a while. Sorry for the extra time it took. Database big."])},
    "please_follow": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Also Remember to follow ", _interpolate(_named("varisHangout")), " to stay up-to-date on any maintenance and updates that may happen"])},
    "update_bugs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please report any issues and bugs on ", _interpolate(_named("pleromaGitlab")), ", as we have changed a lot, and although we test thoroughly and use development versions ourselves, we may have missed some things. We welcome your feedback and suggestions on issues you might encounter, or how to improve Pleroma and Pleroma-FE."])},
    "update_bugs_gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleroma GitLab"])},
    "update_changelog": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For more details on what's changed, see ", _interpolate(_named("theFullChangelog")), "."])},
    "update_changelog_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the full changelog"])},
    "art_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Art by ", _interpolate(_named("linkToArtist"))])}
  },
  "unicode_domain_indicator": {
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This domain contains non-ascii characters."])}
  }
}
export default resource