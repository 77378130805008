import apiService from '../services/api/api.service.js'

const invites = {
  actions: {
    revokeInviteToken ({ rootState }, token) {
      return apiService.revokeInviteToken(token, rootState.users.currentUser.credentials)
    },
    createNewToken ({ rootState }) {
      // Hardcoded One Use Tokens
      return apiService.createInviteToken(1, rootState.users.currentUser.credentials)
    }
  },
  getters: {
    fetchInvites (state, getters, rootState) {
      return apiService.getInviteTokens(rootState.users.currentUser.credentials)
    }
  }
}

export default invites
