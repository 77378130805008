<template>
  <tr>
    <th>
      {{ id }}
    </th>
    <th class="token">
      {{ token }}
    </th>
    <th>
      {{ maxUse }}
    </th>
    <th>
      {{ uses }}
    </th>
    <th>
      {{ used }}
    </th>
    <th>
      <button
        class="btn button-default"
        @click="copyToken"
      >
        {{ $t('invites.copy_token') }}
      </button>
    </th>
    <th>
      <button
        class="btn button-default"
        @click="revokeToken"
      >
        {{ $t('settings.revoke_token') }}
      </button>
    </th>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Invite',
  props: {
    id: { type: Number, default: null },
    token: { type: Date, default: null },
    expiresAt: { type: Date, default: null },
    maxUse: { type: Number, default: null },
    uses: { type: Number, default: 0 },
    used: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters(['instanceDomain'])
  },
  methods: {
    ...mapActions(['revokeInviteToken']),
    copyToken () {
      navigator.clipboard.writeText(this.instanceDomain + '/registration/' + this.token)
    },
    revokeToken () {
      this.revokeInviteToken(this.token)
    }
  }
}
</script>

<style>
.token {
  overflow:hidden;
}
</style>
